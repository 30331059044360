import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import React, { useState } from "react";
import "./index.css";
import { Card, Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { postPriceListConfig } from "../services/getPriceCode";
import { errorMessage, successMessage } from "../utils/alertMessage";
import { getUserData } from "../utils/localStorage";
import "jspdf-autotable";
import jsPDF from "jspdf";

const PriceList = () => {
  const navigate = useNavigate();

  const { state } = useLocation();

  const userData = getUserData();
  let brandId = userData.brandProfileData.id;

  const priceListDTO = state.priceListDTO;

  const handlePrint = () => {
    window.print();
  };

  // State for search query and filtered garments
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredGarments, setFilteredGarments] = useState(null);

  const [masterInputValue, setMasterInputValue] = useState("");

  // Function to handle search input change
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
    filterGarments(event.target.value);
  };

  const allServices = Object.keys(priceListDTO).reduce((services, garment) => {
    Object.keys(priceListDTO[garment]).forEach((category) => {
      Object.keys(priceListDTO[garment][category]).forEach((service) => {
        if (!services.includes(service)) {
          services.push(service);
        }
      });
    });
    return services;
  }, []);

  const uniqueServices = Array.from(new Set(allServices));

  const [formData, setFormData] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [submitButtonPressed, setSubmitButtonPressed] = useState(false);

  const handleInputChange = (event, garmentName, categoryName, serviceName) => {
    const value = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [`${garmentName}-${categoryName}-${serviceName}`]: value,
    }));
  };

  // Function to filter garments based on search query
  const filterGarments = (query) => {
    const filtered = Object.keys(priceListDTO).filter((garment) =>
      garment.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredGarments(filtered);
  };

  // Function to handle master input change
  const handleMasterInputChange = (event) => {
    const value = event.target.value;

    // Update all individual input field values
    const updatedFormData = {};
    Object.keys(priceListDTO).forEach((garment) => {
      Object.keys(priceListDTO[garment]).forEach((category) => {
        Object.keys(priceListDTO[garment][category]).forEach((service) => {
          updatedFormData[`${garment}-${category}-${service}`] = value;
        });
      });
    });
    setFormData(updatedFormData);

    // Update master input value
    setMasterInputValue(value);
  };

  // Function to render garments
  // const renderGarments = () => {
  //   const garments = filteredGarments || Object.keys(priceListDTO);
  //   return garments.map((garment) =>
  //     Object.keys(priceListDTO[garment]).map((category) => (
  //       <tr key={`${garment}-${category}`}>
  //         <td>{garment}</td>
  //         <td>{category}</td>
  //         {Object.keys(priceListDTO[garment][category]).map((service) => (
  //           <td key={`${garment}-${category}-${service}`}>
  //             <InputText
  //               type="number"
  //               style={{ width: 50, height: 10 }}
  //               id={`${garment}-${category}-${service}`}
  //               name={`${garment}-${category}-${service}`}
  //               value={
  //                 // formData[`${garment}-${category}-${service}`] ||
  //                 // priceListDTO[garment][category][service].amount ||
  //                 // ""
  //                 formData[`${garment}-${category}-${service}`] !== undefined
  //                   ? formData[`${garment}-${category}-${service}`]
  //                   : priceListDTO[garment][category][service].amount
  //               }
  //               onChange={(e) =>
  //                 handleInputChange(e, garment, category, service)
  //               }
  //               required
  //             />
  //           </td>
  //         ))}
  //       </tr>
  //     ))
  //   );
  // };

  const renderGarments = () => {
    const garments =
      filteredGarments?.sort() || Object.keys(priceListDTO)?.sort();
    return garments.map((garment) =>
      Object.keys(priceListDTO[garment]).map((category, index) => (
        <tr key={`${garment}-${category}`}>
          <td>{index === 0 ? garment : null}</td>
          <td>{category}</td>
          {uniqueServices.map((service) => {
            const priceData =
              priceListDTO[garment] &&
              priceListDTO[garment][category] &&
              priceListDTO[garment][category][service];

            // Check if priceData is present and if amount is present
            if (priceData && priceData.amount !== undefined) {
              const amount = priceData.amount;

              return (
                <td key={`${garment}-${category}-${service}`}>
                  <InputText
                    type="number"
                    style={{ width: 50, height: 10 }}
                    id={`${garment}-${category}-${service}`}
                    name={`${garment}-${category}-${service}`}
                    value={
                      formData[`${garment}-${category}-${service}`] !==
                      undefined
                        ? formData[`${garment}-${category}-${service}`]
                        : amount
                    }
                    onChange={(e) =>
                      handleInputChange(e, garment, category, service)
                    }
                    required
                  />
                </td>
              );
            } else {
              // If amount is not present, render an empty cell
              return <td key={`${garment}-${category}-${service}`}></td>;
            }
          })}
        </tr>
      ))
    );
  };

  const downloadPDF = (data, uniqueServices, type) => {
    const doc = new jsPDF("landscape");
    const logoImg = new Image();
    // logoImg.src = logoBig;

    // Adjust this as necessary
    // logoImg.src = "/Img/DhobiCart.png";

    // doc.addImage(logoImg, "PNG", 10, 5, 50, 15);
    doc.setFontSize(20);
    doc.text("Tariff - " + state.selectedPriceCode, 120, 25);

    // const tableData = data
    //   .map((garment) => {
    //     return Object.keys(priceListDTO[garment]).map((category, index) => {
    //       const row = [index === 0 ? garment : null, category];
    //       uniqueServices.forEach((service) => {
    //         const priceData = priceListDTO[garment][category][service];
    //         if (priceData && priceData.amount !== undefined) {
    //           const amount = priceData.amount;
    //           row.push(
    //             formData[`${garment}-${category}-${service}`] !== undefined
    //               ? formData[`${garment}-${category}-${service}`]
    //               : amount
    //           );
    //         } else {
    //           row.push("");
    //         }
    //       });
    //       return row;
    //     });
    //   })
    //   .flat();

      const tableData = data
        .map((garment) => {
          return Object.keys(priceListDTO[garment])
            .map((category, index) => {
              const row = [garment, category];
              let includeRow = false;
              uniqueServices.forEach((service) => {
                const priceData = priceListDTO[garment][category][service];
                if (priceData && priceData.amount !== undefined && priceData.amount !== 0) {
                  const amount = priceData.amount;
                  includeRow = true;
                  row.push(
                    formData[`${garment}-${category}-${service}`] !== undefined
                      ? formData[`${garment}-${category}-${service}`]
                      : amount
                  );
                } else {
                  row.push("");
                }
              });
              return includeRow ? row : null;
              // return row;
            })
            .filter((row) => row !== null);
        })
        .flat();

    const tableHeaders = ["Garment Name", "Category", ...uniqueServices];

    doc.autoTable({
      head: [tableHeaders],
      body: tableData,
      startY: 30,
      theme: "grid",
      styles: {
        fontSize: 10,
      },
    });
    if (type === "print") {
      // Create blob URL
      const blob = doc.output("blob");
      const blobURL = URL.createObjectURL(blob);

      // Open blob URL in a new window
      const printWindow = window.open(blobURL);
      if (printWindow) {
        printWindow.onload = () => {
          printWindow.print();
        };
      }
    } else {
      doc.save("Tariff-"  + state.selectedPriceCode + ".pdf");
    }
  };

  const handleSubmit = () => {
    setSubmitButtonPressed(true);
    const submittedData = [];

    Object.keys(priceListDTO).forEach((garment) => {
      Object.keys(priceListDTO[garment]).forEach((category) => {
        Object.keys(priceListDTO[garment][category]).forEach((service) => {
          const serviceData = priceListDTO[garment][category][service];
          const inputValue = formData[`${garment}-${category}-${service}`];
          const originalAmount = serviceData.amount;
          const amount = inputValue !== undefined ? inputValue : originalAmount;

          if (inputValue !== undefined && inputValue !== originalAmount) {
            submittedData.push({
              id: serviceData.id,
              garmentName: serviceData.garmentName,
              category: serviceData.category,
              brandAppServiceName: service,
              garmentCategory: serviceData.garmentCategory,
              brandAppService: serviceData.brandAppService,
              serviceUnit: serviceData.serviceUnit,
              amount: amount,
              minAmount: serviceData.minAmount,
              status: amount > 0,
            });
          }
        });
      });
    });

    let Data = {
      priceListDTO: submittedData,
      serviceUnit: state.serviceUnit,
      appServiceList: state.appServiceList,
      priceId: state.priceId,
    };

    postPriceListConfig(brandId, Data)
      .then((response) => {
        if (!response.data.error) {
          if (response.data.success === false) {
            errorMessage(response.data.message);
            setSubmitButtonPressed(false);
          } else {
            successMessage(response.data.message);
            setSubmitButtonPressed(false);
            navigate("/");
          }
        } else {
          console.log("response.data", response.data);
        }
      })
      .catch((err) => {
        errorMessage();
      });
    setSubmitted(true);
  };

  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      <Navbar
        expand="lg"
        // className=" backgroundColor-white"
        style={{
          backgroundColor: "#FBFBFB",
          //   backgroundImage: "linear-gradient(to bottom, #1752aa, #2b70d7)",
        }}
      >
        <Container>
          {/*<Button
            style={{
              background: "none",
              color: "#1752aa",
              border: "none",
              left: -20,
            }}
            onClick={() => navigate(-1)}
          >
            <i className="pi pi-arrow-left" style={{ fontSize: "2rem" }}></i>
          </Button>*/}
          <Navbar.Brand href="/">
            <img
              style={{ width: "120px" }}
              src="/Img/DhobiCart.png"
              alt="logo"
            />
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto-new">
              <Nav.Link className="nav-link-home" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="nav-link-design" href="/logout">
                <i
                  className="pi pi-power-off"
                  style={{
                    fontSize: "2rem",
                    color: "red",
                    cursor: "pointer",
                  }}
                ></i>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid style={{ marginBottom: 60 }}>
        <div style={{ textAlign: "center", marginBottom: 30 }}>
          <h3>Tariff Management</h3>
        </div>
        <div className="row mb-3">
          <div className="col-md-2">
            <div
              style={{
                // backgroundColor: "#1752AA",
                // color: "white",
                marginTop: 20,
              }}
            >
              <div
                style={{
                  textDecoration: "none",
                  display: "flex",
                  // flex: 1,
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  // width: "280px",
                  // height: "280px",
                  backgroundColor: "#fff",
                  paddingTop: 25,
                  borderRadius: 20,
                  cursor: "pointer",
                  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                  transition: "0.3s",
                  margin: 5,
                  marginTop: 15,
                  marginBottom: 15,
                }}
              >
                <div className="row mb-3">
                  <div className="col-md-12">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img
                        style={{ width: "46px" }}
                        src="/Img/rupee_1.png"
                        alt="logo"
                      />
                    </div>
                    <div style={{ textAlign: "center", height: 70 }}>
                      <h5>
                        {state.type === "price" ? "Price" : "Package"} List
                      </h5>
                      <p className="service-list display-contents">
                        {state.selectedPriceCode}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div
              style={{
                // backgroundColor: "#1752AA",
                // color: "white",
                marginTop: 20,
              }}
            >
              <div
                style={{
                  textDecoration: "none",
                  display: "flex",
                  // flex: 1,
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  // width: "280px",
                  // height: "280px",
                  backgroundColor: "#fff",
                  paddingTop: 25,
                  borderRadius: 20,
                  cursor: "pointer",
                  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                  transition: "0.3s",
                  margin: 5,
                  marginTop: 15,
                  marginBottom: 15,
                }}
              >
                <div className="row mb-3">
                  <div className="col-md-12">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img
                        style={{ width: "46px" }}
                        src="/Img/services.png"
                        alt="logo"
                      />
                    </div>
                    <div style={{ textAlign: "center", height: 70 }}>
                      <h5>App Services</h5>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className="service-list display-contents">
                          {state.appServiceList.join(", ")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div
              style={{
                // backgroundColor: "#1752AA",
                // color: "white",
                marginTop: 20,
              }}
            >
              <div
                style={{
                  textDecoration: "none",
                  display: "flex",
                  // flex: 1,
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  // width: "280px",
                  // height: "280px",
                  backgroundColor: "#fff",
                  paddingTop: 25,
                  borderRadius: 20,
                  cursor: "pointer",
                  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                  transition: "0.3s",
                  margin: 5,
                  marginTop: 15,
                  marginBottom: 15,
                }}
              >
                <div className="row mb-3">
                  <div className="col-md-12">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img
                        style={{ width: "46px", color: "#fff" }}
                        src="/Img/quantity.png"
                        alt="logo"
                      />
                    </div>
                    <div style={{ textAlign: "center", height: 70 }}>
                      <h5>Service Unit</h5>
                      <p className="service-list display-contents">
                        {" "}
                        {state.serviceUnit === "Qty"
                          ? "PIECE"
                          : state.serviceUnit}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Row>
          <Col style={{ margin: 10 }}>
            <main id="main" className="main mb-3">
              <section className="section">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ margin: 20 }}>
                    <InputText
                      value={searchQuery}
                      onChange={handleSearchInputChange}
                      placeholder="Search Garment"
                    />
                  </div>
                  {/** do not delete this */}
                  {/*<div style={{ margin: 20 }}>
                <InputText
                  type="number"
                  value={masterInputValue}
                  onChange={handleMasterInputChange}
                  placeholder="Enter value for all Services"
                />
            </div>*/}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 20,
                      marginTop: 10,
                    }}
                  >
                    <i
                      onClick={() =>
                        downloadPDF(
                          filteredGarments?.sort() ||
                            Object.keys(priceListDTO)?.sort(),
                          uniqueServices,
                          "download"
                        )
                      }
                      className="pi pi-download"
                      style={{
                        fontSize: "2rem",
                        color: "#1e439b",
                        cursor: "pointer",
                      }}
                    ></i>
                    <i
                      onClick={() =>
                        downloadPDF(
                          filteredGarments?.sort() ||
                            Object.keys(priceListDTO)?.sort(),
                          uniqueServices,
                          "print"
                        )
                      }
                      className="pi pi-print"
                      style={{
                        fontSize: "2rem",
                        color: "#ff0101",
                        cursor: "pointer",
                      }}
                    ></i>
                  </div>
                </div>
                <table className="table table-striped ">
                  <thead>
                    <tr>
                      <th>Garment Name</th>
                      <th>Category</th>
                      {uniqueServices.map((service) => (
                        <th key={service}>{service}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>{renderGarments()}</tbody>
                </table>
              </section>
            </main>
          </Col>
        </Row>
        <div style={{ color: "red" }}>
          <h2>
            Note : Blank Fields are considered as 0 (zero) price. Services with
            0 (zero) price will not be displayed in price list{" "}
          </h2>
        </div>
      </Container>
      <div
        style={{
          position: "fixed",
          bottom: 20,
          width: "100%",
          textAlign: "center",
        }}
      >
        <Button
          label="Submit"
          onClick={handleSubmit}
          disabled={submitButtonPressed}
          style={{ position: "relative" }}
          className="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"
        />
      </div>
      <footer style={{ backgroundColor: "#FBFBFB" }}>
        <Container fluid>
          <Row>
            <Col>
              <Nav className="flex social-media-div" style={{ marginLeft: 30 }}>
                <Link
                  to="https://www.youtube.com/channel/UCKdUWjGim18SoHC2jLbFsiw"
                  target="_blank"
                >
                  <i
                    className="pi pi-youtube"
                    style={{
                      fontSize: "2rem",
                      color: "red",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
                <Link
                  to="https://www.instagram.com/dhobicart/?hl=en"
                  target="_blank"
                >
                  <i
                    className="pi pi-instagram"
                    style={{
                      fontSize: "2rem",
                      color: "#d62976",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
                <Link
                  to="https://www.linkedin.com/company/71974228/admin/dashboard/"
                  target="_blank"
                >
                  <i
                    className="pi pi-linkedin"
                    style={{
                      fontSize: "2rem",
                      color: "#0072b1",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
                <Link
                  to="https://www.facebook.com/oneindiatechno"
                  target="_blank"
                >
                  <i
                    className="pi pi-facebook"
                    style={{
                      fontSize: "2rem",
                      color: "#1877F2",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
                <Link
                  to="https://play.google.com/store/apps/details?id=com.dhobicartV2"
                  target="_blank"
                >
                  <i
                    className="pi pi-android"
                    style={{
                      fontSize: "2rem",
                      color: "#a4c639",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
                <Link to="tel:+91 9319518337" target="_blank">
                  <i
                    className="pi pi-phone"
                    style={{
                      fontSize: "2rem",
                      color: "#1877F2",
                      cursor: "pointer",
                    }}
                  ></i>
                </Link>
              </Nav>
            </Col>
            {/*<Col>
              <Link
                to="https://www.oneindiatech.com/?page_id=75"
                target="_blank"
                style={{ textDecoration: "none", fontSize: 20 }}
              >
                Contact Us!
              </Link>
              <p>Phone : +91 9319518337</p>
            </Col>*/}
          </Row>
          <Row>
            <Col>
              <div className="text-center p-3" style={{ fontSize: 16 }}>
                &copy; {new Date().getFullYear()} Copyright:{" "}
                <a className="text-dark" href="https://oneindiatech.com/">
                  One India Technologies Pvt. Ltd.
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default PriceList;
