import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "./index.css";

import React, { useState } from "react";
import { Button } from "primereact/button";
import "./FormDemo.css";
import { InputText } from "primereact/inputtext";
import {
  brandProfile,
  getStoreUser,
  signIn,
  userProfile,
} from "../services/SignIn";
import { setUserData } from "../utils/localStorage";
import { setToken } from "../redux/authSlice";
import { errorMessage } from "../utils/alertMessage";
import { useDispatch } from "react-redux";
import { Alert } from "bootstrap";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const dispatch = useDispatch();

  const [input, setInput] = useState({
    user: "",
    pass: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await signIn({
        username: input.user,
        password: input.pass,
        source: "web",
        version: "2.1",
      });

      if (response && response.data && !response.data.error) {
        const token = response.data.accessToken;
        const userResponse = await userProfile(token);

        if (userResponse && userResponse.data && !userResponse.data.error) {
          const userProfileData = userResponse.data;
          if (["ROLE_STORE", "ROLE_ADMIN"].includes(userProfileData.role)) {
             const brandResponse = await brandProfile(token);

             if (
               brandResponse &&
               brandResponse.data &&
               !brandResponse.data.error
             ) {
               const brandProfileData = brandResponse.data;
               let storeData = {};

               if (userProfileData.role !== "ROLE_ADMIN") {
                 const storeResponse = await getStoreUser(
                   userProfileData.id,
                   token
                 );

                 if (storeResponse && !storeResponse.data.error) {
                   storeData = storeResponse.data.store;
                 } else {
                   console.error("Error fetching store user");
                 }
               }

               setUserData({
                 token,
                 userProfileData,
                 brandProfileData,
                 storeData,
               });
               dispatch(setToken(token));
             } else {
               console.error("Error fetching brand profile");
             }
            
          } else {
           errorMessage("Hello User, You don't have a access");
          }
        } else {
          console.error("Error fetching user profile");
        }
      } else {
        console.error("Error:", response?.data?.message || "Unknown error");
      }
    } catch (error) {
      console.error("Error:", error.message);
      errorMessage();
    }
  };

  return (
    <div className="login-container flex-one">
      <div className="login-section flex-one">
        <div className="flex-one">
          <img style={{ width: "130px" }} src="/Img/DhobiCart.png" alt="logo" />
          <div className="sign-in-div">
            <h1>Sign In</h1>
          </div>
          <div className="to-continue-div">
            <p>To Continue To Dhobi Cart</p>
          </div>
        </div>
        <div className="form-div flex-one">
          <form onSubmit={handleSubmit} className="p-fluid">
            <div>
              <div className="field">
                <div className="input-group has-validation">
                  <InputText
                    value={input.user}
                    onChange={(e) => {
                      setInput({
                        ...input,
                        user: e.target.value,
                      });
                    }}
                    type="text"
                    name="username"
                    className="p-inputtext-lg"
                    id="username"
                    placeholder="UserName"
                  />
                </div>
              </div>

              <div className="field">
                <div className="input-group has-validation">
                  <InputText
                    value={input.pass}
                    onChange={(e) => {
                      setInput({
                        ...input,
                        pass: e.target.value,
                      });
                    }}
                    type={showPassword ? "text" : "password"}
                    name="password"
                    className="p-inputtext-lg"
                    id="password"
                    placeholder="Password"
                  />
                  {/* Move the eye icon inside the input box */}
                  <span
                    className="input-group-addon"
                    onClick={handleTogglePassword}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                      left: "-10px",
                      marginLeft: "-15px",
                    }}
                  >
                    <i
                      className={`pi ${
                        !showPassword ? "pi-eye-slash" : "pi-eye"
                      }`}
                    />
                  </span>
                </div>
              </div>
              <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                <p>
                  For better privacy. Use a private browsing window to sign in.
                </p>
              </div>

              <Button
                type="submit"
                label="Sign In"
                className="mt-2"
                style={{
                  float: "right",
                  backgroundColor: "#0b57d0",
                  borderRadius: 30,
                  width: "25vh",
                }}
              />
            </div>
          </form>
        </div>
      </div>
      <div className="bottom-card-div flex-class">
        <div className="social-media-div flex-class">
          <Link
            to="https://www.youtube.com/channel/UCKdUWjGim18SoHC2jLbFsiw"
            target="_blank"
          >
            <i
              className="pi pi-youtube"
              style={{ fontSize: "2rem", color: "red", cursor: "pointer" }}
            ></i>
          </Link>
          <Link to="https://www.instagram.com/dhobicart/?hl=en" target="_blank">
            <i
              className="pi pi-instagram"
              style={{ fontSize: "2rem", color: "#d62976", cursor: "pointer" }}
            ></i>
          </Link>
          <Link
            to="https://www.linkedin.com/company/71974228/admin/dashboard/"
            target="_blank"
          >
            <i
              className="pi pi-linkedin"
              style={{ fontSize: "2rem", color: "#0072b1", cursor: "pointer" }}
            ></i>
          </Link>
          <Link to="https://www.facebook.com/oneindiatechno" target="_blank">
            <i
              className="pi pi-facebook"
              style={{ fontSize: "2rem", color: "#1877F2", cursor: "pointer" }}
            ></i>
          </Link>
          <Link
            to="https://play.google.com/store/apps/details?id=com.dhobicartV2"
            target="_blank"
          >
            <i
              className="pi pi-android"
              style={{ fontSize: "2rem", color: "#a4c639", cursor: "pointer" }}
            ></i>
          </Link>
          <Link to="tel:+91 9319518337" target="_blank">
            <i
              className="pi pi-phone"
              style={{
                fontSize: "2rem",
                color: "#1877F2",
                cursor: "pointer",
              }}
            ></i>
          </Link>
        </div>
        <div className="flex-class privacy-div">
          <Link
            to="https://www.oneindiatech.com/?page_id=3"
            target="_blank"
            style={{
              color: "#0066a6",
              fontSize: 14,
              fontWeight: 400,
              cursor: "pointer",
              textDecoration: "none",
            }}
          >
            Privacy Policy
          </Link>
          <Link
            to="https://www.oneindiatech.com/?page_id=1080"
            target="_blank"
            style={{
              color: "#0066a6",
              fontSize: 14,
              fontWeight: 400,
              cursor: "pointer",
              textDecoration: "none",
            }}
          >
            Term & Condition
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
