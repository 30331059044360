import axios from "axios";
import getHeaders from "../constants/headers";
import { PRICElIST_BASE_URL, STORE_BASE_URL } from "./BaseURL";

export const getPackageOffer = (brandId) => {
  return axios({
    method: "get",
    url:
      STORE_BASE_URL +
      "/store/api/secure/v1/offer/brand/" +
      brandId +
      "/package-offer",
    headers: getHeaders(),
  });
};

export const getSinglePackageOffer = (brandId, packageOfferId) => {
  return axios({
    method: "get",
    url:
      STORE_BASE_URL +
      "/store/api/secure/v1/offer/brand/" +
      brandId +
      "/package-offer/" +
      packageOfferId,
    headers: getHeaders(),
  });
};

export const updatePackageOffer = (brandId, data) => {
  return axios({
    method: "put",
    url:
      STORE_BASE_URL +
      "/store/api/secure/v1/offer/brand/" +
      brandId +
      "/package-offer",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};