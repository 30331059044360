import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";

import Login from "../pages/Login";
import Logout from "../pages/Logout";
import Root from "../authLayout/Root";
import AuthLayout from "../authLayout/AuthLayout";
import Authentication from "../authLayout/Authentication";
import PriceListSelection from "../pages/PriceListSelection";
import PriceList from "../pages/PriceList";
import Dashboard from "../pages/Dashboard";
import PriceListOriginal from "../pages/PriceListOriginal";
import PackageListSelection from "../pages/PackageListSelection";
import PackageList from "../pages/PackageList";
import GarmentTag from "../pages/GarmentTag";
import PickupRequest from "../pages/PickupRequest";
import OfferManagement from "../pages/OfferManagement/OfferManagement";
import OfferGarmentSelection from "../pages/OfferManagement/OffersGarmentSelection";
import TrackOrder from "../TrackOrder/TrackOrder";
import PackageOfferManagement from "../pages/PackageOfferManagement/PackageOfferManagement";
import PackageOffersGarmentSelection from "../pages/PackageOfferManagement/PackageOffersGarmentSelection";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Root />}>
      <Route element={<AuthLayout />}>
        <Route path="login" element={<Login />} />
      </Route>
      <Route path="/" element={<Authentication />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="tariff-list-selection" element={<PriceListSelection />} />
        <Route
          path="package-list-selection"
          element={<PackageListSelection />}
        />
        <Route path="tariff-list" element={<PriceList />} />
        <Route path="package-list" element={<PackageList />} />
        <Route path="tariff-list-original" element={<PriceListOriginal />} />
        <Route path="garment-tag" element={<GarmentTag />} />
        <Route path="pickup-request" element={<PickupRequest />} />
        <Route path="offer-management" element={<OfferManagement />} />
        <Route
          path="package-offer-management"
          element={<PackageOfferManagement />}
        />
        <Route
          path="offer-garment-selection"
          element={<OfferGarmentSelection />}
        />
        <Route
          path="package-offer-garment-selection"
          element={<PackageOffersGarmentSelection />}
        />

        <Route path="order-tracker" element={<TrackOrder />} />
      </Route>
      <Route path="logout" element={<Logout />} />
    </Route>
  )
);

export default router;
